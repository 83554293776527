<template>
    <div>
        <b-card-code title="Mis Proyectos" no-body>
            <b-table 
            v-if="projects.length>0"
            ref="selectableTable"
            :fields="fields" 
            :items="projects" 
            responsive
            selectable
            class="mb-0"
            :select-mode="selectMode"
            @row-selected="onRowSelected">

                <template #cell(investmentRequired)="data"> 
                  <b-card-text>
                    <p v-if="data.item.projectType == 'Levante' || data.item.projectType == 'Ceba'">{{parseFloat(data.value).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} Kg</p> 
                    <p v-if="data.item.projectType == 'Cria'">{{parseFloat(data.item.investmentRequired)}} Sty</p>             
                  </b-card-text>
                </template>

                <template #cell(projectProgress)="data">
                    <b-card-text class="mb-0">
                        Porcentaje: {{parseFloat(data.item.investmentCollected).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} Kg ({{ (data.item.projectProgress).toFixed(2)+'%' }})
                    </b-card-text>
                    <b-progress :value="data.item.projectProgress" max="100" height="10px" variant="success" striped/>
                </template>

                <template #cell(projectStatus)="data">
                    <b-badge pill variant="secondary"> {{ data.value }}</b-badge>
                </template> 
                
                <template #cell(createDate)="data">
                    {{((data.item.createDate))}}
                </template>            

            </b-table>
            <b-row v-if="projects.length <= 0" >
                <b-col xl="4" md="6" lg="4" offset-lg="4" offset-md="3" offset-xl="4"  >
                    <b-alert variant="primary" show>
                        <div class="alert-body">
                            <span><strong>No tienes ningun proyecto a tu nombre</strong></span>
                        </div>
                    </b-alert>
                </b-col>
            </b-row>
            
        </b-card-code>

        <b-card-code>
            <b-row>
                <b-col v-if="!processing" xl="2" md="4" lg="2" offset-lg="5" offset-xl="5"  offset-md="4">
                    <b-button variant="primary" block @click="addNewProject">
                        <feather-icon icon="PlusIcon" class="mr-50"/>
                        <span class="align-middle">Nuevo Proyecto</span>
                    </b-button>
                </b-col>

                <b-col v-if="processing" xl="2" md="4" lg="2" offset-lg="5" offset-xl="5"  offset-md="4">
                    <b-button variant="primary" disabled block><b-spinner small label="Cargando..." /></b-button>
                </b-col>
            </b-row>                   
        </b-card-code>

        <b-modal ref="my-modal" hide-footer title="Que deseas hacer?">
          <b-button class="mt-1" variant="primary" block @click="viewProjectPublicDetails">
                <feather-icon icon="EyeIcon" class="mr-50"/>
                <span class="align-middle">Ver Proyecto</span>
            </b-button>        
            <b-button class="mt-1" variant="primary" block @click="followUpProject">
                <feather-icon icon="ClipboardIcon" class="mr-50"/>
                <span class="align-middle">Seguimiento Proyecto</span>
            </b-button>     
            <b-button class="mt-2" variant="outline-primary" block @click="editProject">
                <feather-icon icon="EditIcon" class="mr-50"/>
                <span class="align-middle">Editar Proyecto</span>
            </b-button>
            <b-button class="mt-2" variant="outline-danger" block @click="deleteModal">
                <feather-icon icon="Trash2Icon" class="mr-50"/>
                <span class="align-middle">Eliminar Proyecto</span>
            </b-button>
        </b-modal>
    </div>
</template>

<script>
import axios from '@axios'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BRow, BCol, BTable, BProgress, BBadge, BModal, BButton, BSpinner, BAlert, BCardText, } from 'bootstrap-vue'

export default {
  components: {
    BRow, BCol,
    BCardCode,
    BTable,
    BProgress,
    BCardText,
    BBadge,
    BModal,
    BButton,
    BSpinner,
    BAlert,
  },
  data() {
    return {
      selectMode: 'single',
      fields: [
        { key: 'projectCode', label: 'Código' },        
        { key: 'projectName', label: 'Nombre' },
        { key: 'projectType', label: 'Tipo de Proyecto'},
        { key: 'createDate', label: 'Fecha Creación' },
        { key: 'projectStatus', label: 'Fase' },
        { key: 'investmentRequired', label: 'Inversion Solicitada' },        
        { key: 'projectProgress', label: 'Progreso' },
      ],
      projects: [],
      selectedProject: {},
    }
  },
  created() {
    this.GetProjectsByProducerId();
  },
  methods: {
    onRowSelected(items) {
        this.selectedProject = items[0];
        if(this.selectedProject) this.showModal();
    },
    GetProjectsByProducerId() {
        this.processing = true;
        var userData = getUserData()
        axios.get('/Project/GetProjectsByProducerId', { params: { producerID: userData.userID }})
        .then(response => {
           this.processing = false;
           this.projects = response.data;
           if(this.projects.length > 0){
               this.projects.forEach(function(item, index){
                item.createDate = item.createDate.substring(0,10)
                if(item.investmentRequired != null && item.investmentCollected != null){
                    item.projectProgress = (item.investmentCollected * 100) / item.investmentRequired;
                } else {                      
                  item.projectProgress = 0;
                  item.investmentCollected = 0;
                }                 
               });
           }
        })
        .catch(error => {
          this.processing = false;
            if(error.response){
              this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            } else {
              this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
        })
    },
    DeleteProject() {
        this.processing = true;
        console.log('aqui 2',this.selectedProject);
        axios.get('/Project/DeleteProject', { params: { projectID: this.selectedProject.projectID }})
        .then(response => {
           this.processing = false;
          if(response.data) {
            this.$bvToast.toast("Proyecto eliminado de forma exitosa", { title: 'Ok', variant: 'success', solid: true, });
            this.$router.go();
          } else {
            this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
          }
        })
        .catch(error => {          
          this.processing = false;
            if(error.response){
              this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            } else {
              this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
        })
    },
    
    showModal() {
      this.$refs['my-modal'].show()
    },
    editProject() {
      this.$refs['my-modal'].hide()
      this.$router.push('create-edit-project?projectID=' + this.selectedProject.projectID)
    },
    deleteModal() {
        this.$refs['my-modal'].hide()
        this.DeleteProject();
    },
    viewProjectPublicDetails() {
      this.$refs['my-modal'].hide()
      let route = this.$router.resolve({path: 'public-project-details?projectCode=' + this.selectedProject.projectCode});
      window.open(route.href, '_blank');
    },
    addNewProject(){
        this.$router.push('create-edit-project')
    },
    followUpProject(){
        this.$refs['my-modal'].hide()
        this.$router.push('follow-up-project?projectID=' + this.selectedProject.projectID)
    },
  },
}
</script>
